import React, { Suspense, lazy } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router';
import '../public/css/common.css';
import '../public/css/font-mfizz.css';
import '../public/css/flaticon.css';
import createBrowserHistory from '@common/history2';
import CommonOverlayLoading from '@common/OverlayLoading';
import Store from './data/Store';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<CommonOverlayLoading />}>
      <Component {...props} />
    </Suspense>
  );  

// Não remover import ChiefAlternative, error de tela branca.
import ChiefAlternative from './components/chiefAlternative/ChiefContainer';


const RiskManagementContainer = Loader(
  lazy(() => import('./components/riskmanagement/RiskManagementContainer'))
);

const ConfigurationContainer = Loader(
  lazy(() => import('./components/configuration/ConfigurationContainer'))
);

const SideDishContainer = Loader(
  lazy(() => import('./components/riskmanagement/SideDish/SideDishContainer'))
);

const ContractContainer = Loader(
  lazy(() => import('./components/contract/ContractContainer'))
);

const AssessmentContainer = Loader(
  lazy(() => import('./components/assessment/AssessmentContainer'))
);

const Bsc = Loader(
  lazy(() => import('./components/bsc2'))
);

const CapacityPlanContainer = Loader(
  lazy(() => import('./components/CapacityPlan2'))
);

const SlaForm = Loader(
  lazy(() => import('./components/slaForm'))
);

const Build = Loader(
  lazy(() => import('./components/Build/Index'))
);

const BuildHome = Loader(
  lazy(() => import('./components/Build/Buildhome'))
);

const AnswerContainer = Loader(
  lazy(() => import('./components/answer/AnswerContainer'))
);

const AuditContainer = Loader(
  lazy(() => import('./components/audit/AuditContainer'))
);

const AuditContainerNew = Loader(
  lazy(() => import('./components/audit/AuditContainer'))
);

const MonitorContainer = Loader(
  lazy(() => import('./components/monitor2'))
);

const OKR = Loader(
  lazy(() => import('./components/OKR'))
);

const LoginContainer = Loader(
  lazy(() => import('./components/login/LoginContainer'))
);

const Avaliation = Loader(
  lazy(() => import('./components/avaliation/AvaliationPage'))
);

const TicketContainer = Loader(
  lazy(() => import('./components/tickets/TicketContainer'))
);
// import SlaContainer from './components/sla/SlaContainer';

const UserContainer = Loader(
  lazy(() => import('./components/user/UserContainer'))
);

const ReportContainer = Loader(
  lazy(() => import('./components/reports/ReportContainer'))
);

const AnalystReportCalls = Loader(
  lazy(() => import('./components/reports/reportpages/gsd/AnalystReportCalls'))
);

const WelcomeContainer = Loader(
  lazy(() => import('./components/welcome/WelcomeContainer'))
);

// import CategoryContainer from './components/category/CategoryContainer';
const TagContainer = Loader(
  lazy(() => import('./components/tags/TagContainer'))
);

// import GroupContainer from './components/group/GroupContainer';

// import DepartmentContainer from './components/department/DepartmentContainer';
const TypeContractContainer = Loader(
  lazy(() => import('./components/typecontracts/TypeContractContainer'))
);
// import TaskContainer from './components/task/TaskContainer';
const ServiceOrderContainer = Loader(
  lazy(() => import('./components/serviceorder/ServiceOrderContainer'))
);

const ServiceOrderContainerForm = Loader(
  lazy(() => import('./components/serviceorder/ServiceOrderContainer'))
);

const GuacamoleView = Loader(
  lazy(() => import('./components/guaca/index'))
);

const RequestContainer = Loader(
  lazy(() => import('./components/request/RequestContainer'))
);

const CIOContainer = Loader(
  lazy(() => import('./components/dashboards/CIOContainer'))
);

const CioPageGraph = Loader(
  lazy(() => import('./components/dashboards/CioPageGraph'))
);

// import GCFContainer from './components/dashboards/GCFContainer'; //antigo

const GCFContainer = Loader(
  lazy(() => import('./components/GCF'))
);

const GIContainer = Loader(
  lazy(() => import('./components/GI2'))
);

// import GIContainer from './components/dashboards/GIContainer' //antigo

const GSDContainer = Loader(
  lazy(() => import('./components/dashboards/GSDContainer'))
);

const GSDContainerSac = Loader(
  lazy(() => import('./components/sac/AttendanceGADContainer'))
);

const SolutionContainer = Loader(
  lazy(() => import('./components/solution/SolutionContainer'))
);

const ProjectManagementContainer = Loader(
  lazy(() => import('./components/projectmanagement/ProjectManagementContainer'))
);

const CalendarioActivities = Loader(
  lazy(() => import('./components/Calendario/CalendarioContainer'))
);

const CalendarioChangeActivities = Loader(
  lazy(() => import('./components/changemanagement/Calendario/CalendarioContainer'))
);

const DownloadAgent = Loader(
  lazy(() => import('./components/monitor/DownloadAgent'))
);

const ChangeManagementContainer = Loader(
  lazy(() => import('./components/changemanagement/ChangeManagementContainer'))
);

const InventoryContainer = Loader(
  lazy(() => import('./components/inventory/InventoryContainer'))
);
// import MailContainer from './components/mail/MailContainer';
// import CompanyTimeContainer from './components/companytime/CompanyTimeContainer';

const StockContainer = Loader(
  lazy(() => import('./components/stock/StockContainer'))
);
// import DeviceType from './components/devicetype/DeviceTypeContainer';

const Site = Loader(
  lazy(() => import('./components/site/SiteContainer'))
);

const Panel = Loader(
  lazy(() => import('./components/panel/PanelContainer'))
);

const saveContract = Loader(
  lazy(() => import('./components/contract/ContractContainer'))
);
// import ad from './components/ad/AdContainer';
// import Discovery from './components/discovery/DiscoveryContainer';

const DeviceConf = Loader(
  lazy(() => import('./components/monitor/MonitorContainer'))
);

const TabelaRisco = Loader(
  lazy(() => import('./components/gi/TabelaRisco'))
);

const SubscribeContracts = Loader(
  lazy(() => import('./components/SubscribeContracts'))
);

const GV = Loader(
  lazy(() => import('./components/GV/GV'))
);

const TicketHistoric = Loader(
  lazy(() => import('./components/tickets/TicketHistoric'))
);

const DemandmanagementContainer = Loader(
  lazy(() => import('./components/demandmanagement/DemandmanagementContainer.js'))
);
// import PerfilContainer from './components/perfil/PerfilContainer.js'

const BudgetControlContainer2 = Loader(
  lazy(() => import('./components/BudgetControl2'))
);
// import GeneralParametersContainer from './components/GeneralParameters/GeneralParametersContainer';

const ModelCanvasContainer = Loader(
  lazy(() => import('./components/ModelCanvas/ModelCanvasContainer'))
);

const DashboardContent = Loader(
  lazy(() => import('./components/dashboard/DashboardContent'))
);
// import SnmpContainer from './components/snmp/snmpContainer';

// import TicketManagement from './components/TicketManagement';
const TicketManagement = Loader(
  lazy(() => import('./components/TicketManagement'))
);
/*------------------------------------------projects-------------------------------------------------------------------*/

const ProjectManagementList = Loader(
  lazy(() => import('./components/Projects/ProjectsList'))
);

const ProjectPage = Loader(
  lazy(() => import('./components/Projects/Project'))
);

const ProjectManagementPmo = Loader(
  lazy(() => import('./components/Projects/PMO'))
);

const ProjectManagementReport = Loader(
  lazy(() => import('./components/Projects/Report'))
);
/*------------------------------------------Prioritization-------------------------------------------------------------------*/

const PrioritizationHome = Loader(
  lazy(() => import('./components/projectmanagement/Prioritization/Home'))
);

const PrioritizationAvaliaton = Loader(
  lazy(() => import('./components/projectmanagement/Prioritization/Avaliation'))
);

const PrioritizationConfiguration = Loader(
  lazy(() => import('./components/projectmanagement/Prioritization/Configuration'))
);
/*------------------------------------------Sprint-------------------------------------------------------------------*/
const SprintHome = Loader(
  lazy(() => import('./components/projectmanagement/Sprint/Home'))
);

const SprintKanban = Loader(
  lazy(() => import('./components/projectmanagement/Sprint/Kanban'))
);
/*------------------------------------------OKR-------------------------------------------------------------------*/
//import OKR from './components/OKR';
/*------------------------------------------HOME-------------------------------------------------------------------*/

const Home = Loader(
  lazy(() => import('./components/Home'))
);

const AvaliationOrderContainer = Loader(
  lazy(() => import('./components/serviceorder/AvaliationOrder'))
);

const DashboardTaskManagement = Loader(
  lazy(() => import('./components/taskmanagement/Dashboard'))
);

const ApproveTask = Loader(
  lazy(() => import('./components/taskmanagement/ApproveTask'))
);

const TechTask = Loader(
  lazy(() => import('./components/taskmanagement/TechTask'))
);

const TaskCalendar = Loader(
  lazy(() => import('./components/taskmanagement/Calendar/TaskCalendar'))
);

const TicketTask = Loader(
  lazy(() => import('./components/taskmanagement/Ticket'))
);

const PortalContainer = Loader(
  lazy(() => import('./components/portal/PortalContainer'))
);

const SacConsumerBase = Loader(
  lazy(() => import('./components/sac/consumer/SacConsumerBase'))
);

const SacThemeList = Loader(
  lazy(() => import('./components/sac/theme/SacThemeList'))
);

const TopicList = Loader(
  lazy(() => import('./components/sac/topic/TopicList'))
);

const SubjectContainer = Loader(
  lazy(() => import('./components/sac/subject/SubjectContainer'))
);

const AttendanceContainer = Loader(
  lazy(() => import('./components/sac/attendance/AttendanceContainer'))
);

const ListTasksAttendance = Loader(
  lazy(() => import('./components/sac/listtask'))
);

const ServicePortal = (
  lazy(() => import('./components/ServicePortal'))
);

const ApprovalCenterContainer = (
  lazy(() => import('./components/ApprovalCenter/ApprovalCenterContainer'))
);

import Highcharts from 'highcharts';
import moment from 'moment';

const { language, language_type } = Store;

Highcharts.setOptions({
  lang: {
    downloadCSV: language.DOWNLOAD_CSV,
    downloadJPEG: language.DOWNLOAD_JPEG,
    downloadMIDI: language.DOWNLOAD_MIDI,
    downloadPDF: language.DOWNLOAD_PDF,
    downloadPNG: language.DOWNLOAD_PNG,
    downloadSVG: language.DOWNLOAD_SVG,
    downloadXLS: language.DOWNLOAD_XLS,
    viewFullscreen: language.VIEW_FULL_SCREEN,
    printChart: language.PRINT_CHART,
  }
});

moment.locale(language_type);

// console.log({ language_type });


const PrivateRoute = ({ component, componentProps, ...rest }) => {
  if (Store.getToken()) {
    const user = Store.getUserLoged();
    if (user.use_service_portal_only && !rest.location.pathname.startsWith('/dash/service-portal')) {
      return <Redirect push to='/dash/service-portal' />
    }

    return (
      <Route
        {...rest}
        render={(props) =>
          React.createElement(component, { ...props, ...componentProps })
        }
      />
    );
  } else {
    const url = new URL(window.location.href);
    const returnUrl = url.pathname ? '?returnUrl=' + encodeURI(url.href.replace(url.origin, '')) : '';
    window.location.href = window.location.origin + '/login/' + returnUrl
  }
};

export default class Routes extends React.Component {
  render() {
    return (
      <Router history={createBrowserHistory}>
        <Switch>
          {/* ---------------------------------------OKR----------------------------------------- */}
          <PrivateRoute exact path="/dash/okr" component={OKR} />
          {/** ----------LOGIN--------- */}
          <Route exact path="/" component={LoginContainer} />
          <Route exact path="/login" component={LoginContainer} />
          <Route exact path="/redefine" component={LoginContainer} />
          <Route exact path="/expired" component={LoginContainer} />
          <Route exact path="/avaliation" component={Avaliation} />
          {/* ---------------------------------------Sprint----------------------------------------- */}
          <PrivateRoute
            exact
            path="/dash/projectmanagement/sprint"
            component={SprintHome}
          />
          <PrivateRoute
            exact
            path="/dash/projectmanagement/sprint/:id/kanban"
            component={SprintKanban}
          />
          {/* ------------------------------------------------------------------------------------- */}
          <PrivateRoute
            path="/dash/service-portal"
            component={ServicePortal}
          />
          <PrivateRoute exact path="/welcome" component={WelcomeContainer} />
          <PrivateRoute exact path="/dash" component={Home} />
          <PrivateRoute exact path="/dash/modules" component={CioPageGraph} />
          <PrivateRoute exact path="/dash/cio" component={CIOContainer} />
          <PrivateRoute
            exact
            path="/dash/SubscribeContracts"
            component={SubscribeContracts}
          />
          <PrivateRoute exact path="/dash/gv" component={GV} />
          <PrivateRoute
            exact
            path="/dash/demandmanagement"
            component={DemandmanagementContainer}
          />
          {/* <PrivateRoute exact path="/dash/perfil" component={PerfilContainer} /> */}
          <PrivateRoute
            exact
            path="/dash/budgetcontrol2"
            component={BudgetControlContainer2}
          />
          {/* <PrivateRoute exact path="/dash/generalparameters" component={GeneralParametersContainer} /> */}
          <PrivateRoute
            exact
            path="/dash/searchTickets"
            component={TicketHistoric}
          />
          <PrivateRoute
            exact
            path="/dash/ticketmanagement"
            component={TicketManagement}
          />
          <PrivateRoute
            exact
            path="/dash/assessment"
            component={AssessmentContainer}
          />
          <PrivateRoute
            exact
            path="/dash/guacamoleview"
            component={GuacamoleView}
          />
          <PrivateRoute exact path="/dash/audit" component={AuditContainer} />
          <PrivateRoute
            exact
            path="/dash/capacityPlan"
            component={CapacityPlanContainer}
          />
          {/* --------------------------------------SNMP--------------------------------------------------- */}
          {/* <PrivateRoute
              exact
              path={['/dash/snmp', /\/dash\/snmp\/(new|edit|clone)/]}
              component={SnmpContainer}
            /> */}
          {/* --------------------------------------Dashboard--------------------------------------------------- */}
          <PrivateRoute
            exact
            path="/dash/approvalcenter"
            component={ApprovalCenterContainer}
          />
          <PrivateRoute
            exact
            path="/dash/dashboard"
            component={DashboardContent}
          />
          {/* --------------------------------------Canvas--------------------------------------------------- */}
          <PrivateRoute
            exact
            path="/dash/canvas"
            component={ModelCanvasContainer}
          />
          {/* --------------------------------------User--------------------------------------------------- */}
          <PrivateRoute
            exact
            path={[
              '/dash/changeData',
              '/dash/generaluser',
              '/dash/changePass',
              '/dash/user',
              '/dash/user/sponsor',
              '/dash/user/sponsor/new',
              '/dash/user/outsourced',
              '/dash/user/outsourced/new',
              '/dash/user/outsourced/edit',
            ]}
            component={UserContainer}
          />
          {/* --------------------------------------Company--------------------------------------------------- */}
          {/* <PrivateRoute
              exact
              path={[
                "/dash/company",
                "/dash/company/new",
                "/dash/company/edit",
                "/dash/company/new/task",
                "/dash/company/new/department",
              ]}
              component={CompanyContainer}
            /> */}
          {/* --------------------------------------Matriz de risco--------------------------------------------------- */}
          <PrivateRoute exact path="/dash/gri" component={TabelaRisco} />
          <PrivateRoute exact path="/dash/sla" component={SlaForm} />
          {/* --------------------------------------contracts--------------------------------------------------------- */}
          <PrivateRoute
            exact
            path={[
              '/dash/contracts',
              '/dash/contracts/new',
              '/dash/contracts/edit',
            ]}
            component={ContractContainer}
          />
          {/* --------------------------------------serviceorder--------------------------------------------------------- */}
          <PrivateRoute
            exact
            path={[
              '/dash/serviceorder',
              '/dash/serviceorder/view',
              '/dash/serviceorder/new',
              '/dash/serviceorder/edit',
            ]}
            component={ServiceOrderContainer}
          />
          <PrivateRoute
            exact
            path={['/dash/serviceorder/avaliation']}
            component={AvaliationOrderContainer}
          />
          {/* --------------------------------------Stock--------------------------------------------------------- */}
          <PrivateRoute
            exact
            path="/dash/stock"
            // eslint-disable-next-line react/jsx-no-duplicate-props
            path={[
              '/dash/stock',
              '/dash/stock/historic',
              '/dash/stock/historic/detail',
            ]}
            component={StockContainer}
          />
          {/* --------------------------------------Inventory--------------------------------------------------------- */}
          <PrivateRoute
            exact
            path={[
              '/dash/inventory',
              '/dash/inventory/software',
              '/dash/inventory/mac',
              '/dash/inventory/hardware',
              '/dash/inventory/service',
              '/dash/inventory/vulnerability',
            ]}
            component={InventoryContainer}
          />
          {/* --------------------------------------Solution--------------------------------------------------------- */}
          <PrivateRoute
            exact
            path={[
              '/dash/solution',
              '/dash/solution/new',
              '/dash/solution/edit',
              '/dash/solution/view',
            ]}
            component={SolutionContainer}
          />
          {/* --------------------------------------Riskmanagement--------------------------------------------------------- */}
          <PrivateRoute
            exact
            path="/dash/riskmanagement"
            componentProps={{ risk: 0 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/iso_27001"
            componentProps={{ risk: 1 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/iso_20000"
            componentProps={{ risk: 2 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/cobit"
            componentProps={{ risk: 3 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/backup_management"
            componentProps={{ risk: 4 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/it_evolution_management"
            componentProps={{ risk: 5 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/lgpd"
            componentProps={{ risk: 6 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/iso_9001"
            componentProps={{ risk: 7 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/iso_37001"
            componentProps={{ risk: 8 }}
            component={RiskManagementContainer}
          />

          <PrivateRoute
            exact
            path="/dash/riskmanagement/iso_27001/config"
            componentProps={{ risk: 1 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/iso_20000/config"
            componentProps={{ risk: 2 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/cobit/config"
            componentProps={{ risk: 3 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/backup_management/config"
            componentProps={{ risk: 4 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/it_evolution_management/config"
            componentProps={{ risk: 5 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/lgpd/config"
            componentProps={{ risk: 6 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/iso_9001/config"
            componentProps={{ risk: 7 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/riskmanagement/iso_37001/config"
            componentProps={{ risk: 8 }}
            component={RiskManagementContainer}
          />
          <PrivateRoute exact path="/dash/build" component={Build} />
          <PrivateRoute exact path="/dash/update" component={BuildHome} />
          <PrivateRoute
            exact
            path="/dash/sideDish"
            component={SideDishContainer}
          />
          {/* ----------------------------------------Department----------------------------------------------------------- */}
          {/* <PrivateRoute
              exact
              path={[
                "/dash/department",
                "/dash/department/new",
                "/dash/department/edit",

                "/dash/subdepartment",
                "/dash/subdepartment/new",
                "/dash/subdepartment/edit",
              ]}
              component={DepartmentContainer}
            /> */}
          {/* -----------------------------------------------Device----------------------------------------------------------- */}
          {/* <PrivateRoute
              exact
              path={[
                "/dash/device",
                "/dash/device/new",
                "/dash/device/edit",
                "/dash/device/clone",
                "/dash/deviceInventory"
              ]}
              component={DeviceContainer}
            /> */}
          {/* --------------------------------------------Task--------------------------------------------------------------- */}
          {/* <PrivateRoute
              exact
              path={[
                "/dash/task",
                "/dash/task/new",
                "/dash/task/edit",
              ]}
              component={TaskContainer}
            /> */}
          {/* --------------------------------------------Catalog--------------------------------------------------------------- */}
          {/* <PrivateRoute
              exact
              path={[
                '/dash/catalog',
                '/dash/catalog/new',
                '/dash/catalog/add/assigment',
                '/dash/catalog/edit',
              ]}
              component={CatalogContainer}
            /> */}
          {/* --------------------------------------------Category--------------------------------------------------------------- */}
          {/* <PrivateRoute
              exact
              path={[
                '/dash/category',
                '/dash/category/new',
                '/dash/category/edit',
                '/dash/category/add/catalog'
              ]}
              component={CategoryContainer}
            /> */}
          {/* --------------------------------------------Group--------------------------------------------------------------- */}
          {/* <PrivateRoute
              exact
              path={[
                '/dash/group',
                '/dash/group/new',
                '/dash/group/edit',
                '/dash/group/add/user'
              ]}
              component={GroupContainer}
            /> */}
          {/* --------------------------------------------Projectmanagement--------------------------------------------------------------- */}
          <PrivateRoute
            exact
            path={[
              localStorage.getItem('view_past_project')
                ? '/dash/gp'
                : undefined,
              '/dash/gp/list',
              '/dash/gp/list/detail',
              '/dash/gp/list/edit',
              '/dash/gp/list/form',
              '/dash/gp/list/new',
              // '/dash/gp/prioritization'
              //  '/dash/gp/pmo',
              // '/dash/gp/prioritization/list',
            ].filter(Boolean)}
            component={ProjectManagementContainer}
          />
          <PrivateRoute
            exact
            path="/dash/gp/prioritization"
            component={PrioritizationHome}
          />
          <PrivateRoute
            exact
            path="/dash/gp/prioritization/avaliation"
            component={PrioritizationAvaliaton}
          />
          <PrivateRoute
            exact
            path="/dash/gp/prioritization/avaliation/configuration"
            component={PrioritizationConfiguration}
          />
          <PrivateRoute
            exact
            path={['/dash/gp/report']}
            component={ProjectManagementReport}
          />
          <PrivateRoute
            exact
            path={['/dash/gp/pmo']}
            component={ProjectManagementPmo}
          />
          <PrivateRoute
            exact
            path={['/dash/gp/']}
            component={ProjectManagementList}
          />
          <PrivateRoute exact path={['/dash/gp/:id']} component={ProjectPage} />
          <PrivateRoute
            exact
            path={['/dash/calendar']}
            component={CalendarioActivities}
          />
          {/* --------------------------------------------Changemanagement--------------------------------------------------------------- */}
          <PrivateRoute
            exact
            path={[
              '/dash/changemanagement',
              '/dash/changemanagement/new',
              '/dash/changemanagement/edit',
              '/dash/changemanagement/view',
              '/dash/changemanagement/analyze',
              '/dash/changemanagement/risk_milestone',
            ]}
            component={ChangeManagementContainer}
          />
          <PrivateRoute
            exact
            path={['/dash/changemanagement/calendar']}
            component={CalendarioChangeActivities}
          />
          {/* --------------------------------------------Request container--------------------------------------------------------------- */}
          {/* --------------------------------------------Configuration--------------------------------------------------------------- */}
          <PrivateRoute
            exact
            path={[
              '/dash/request',
              '/dash/requestapprove',
              '/dash/request/new',
              '/dash/request/view',
              '/dash/request/categorize',
            ]}
            component={RequestContainer}
          />
          {/* --------------------------------------------Configuration--------------------------------------------------------------- */}
          <PrivateRoute
            exact
            path={[
              // ------------------CONFIGURAÇÕES GERAIS-----------------------------
              '/dash/configuration',
              '/dash/configuration/perfil',
              '/dash/configuration/outagerecurrence',
              '/dash/configuration/users',
              '/dash/configuration/userspermissions',
              '/dash/configuration/generalparameters',
              '/dash/configuration/extensionsfile',
              '/dash/configuration/centralhelp',
              '/dash/configuration/passwordquestions',
              // ------------------CENTRAL DE NOTIFICAÇÕES-----------------------------
              '/dash/configuration/notificationcenter',
              '/dash/configuration/notificationcentertags',
              // -------------------------GESTÃO DE SLA-----------------------------
              '/dash/configuration/slawork',
              '/dash/configuration/slaconfig',
              '/dash/configuration/ticketallawanse',
              '/dash/configuration/statusticketallawanse',
              '/dash/configuration/slareprocessing',
              '/dash/configuration/slaholidays',
              // -------------------------EMPRESAS----------------------------------
              '/dash/configuration/company',
              '/dash/configuration/department',
              '/dash/configuration/subdepartment',
              '/dash/configuration/occupation',
              '/dash/configuration/ldap',
              '/dash/configuration/companytime',
              '/dash/configuration/location',
              '/dash/configuration/email',
              '/dash/configuration/squad',
              // -------------------------GESTÃO DE CONTRATO----------------------------------
              '/dash/configuration/contracts',
              '/dash/configuration/sla',
              '/dash/configuration/slaform',
              '/dash/configuration/templateemail',
              // -------------------------GESTÃO DE DISPONIBILIDADE----------------------------------
              '/dash/configuration/device',
              '/dash/configuration/snmp',
              '/dash/configuration/devicetype',
              '/dash/configuration/deviceIC',
              // -------------------------GESTÃO DE DEMANDA----------------------------------
              '/dash/configuration/automation',
              '/dash/configuration/task',
              '/dash/configuration/subcategories',
              '/dash/configuration/categories',
              '/dash/configuration/techgroup',
              '/dash/configuration/template',
              '/dash/configuration/changecab',
              '/dash/configuration/changerisk',
              '/dash/configuration/eventcab',
              '/dash/configuration/answer',
              '/dash/configuration/statusawaiting',
              '/dash/configuration/techEvaluationRegistration',
              '/dash/configuration/passwordsafe',
              '/dash/configuration/changemanagement',
              '/dash/configuration/changemanagementworkflow',
              '/dash/configuration/changemanagementgroup',
              '/dash/configuration/approvalshistoric',
              '/dash/configuration/resolutioncategory',
              '/dash/configuration/resolutioncode',
              '/dash/configuration/channel-management',
              // -------------------------GESTÃO DE PRJETOS----------------------------------
              '/dash/configuration/project',
              '/dash/configuration/projectstatus',
              '/dash/configuration/coin-management',
              // -------------------------Usuário Alternativo----------------------------------
              '/dash/configuration/cheifAlternative',
              '/dash/configuration/effortvalue',
              '/dash/configuration/location',
              // -------------------------Auditoria----------------------------------
              '/dash/audit/users',
              '/dash/audit/company',
              '/dash/audit/catalog',
              '/dash/audit/form',
              '/dash/audit/flow-approve-gmud',
            ]}
            component={ConfigurationContainer}
          />
          <PrivateRoute
            exact
            path="/dash/monitor"
            component={MonitorContainer}
          />
          <PrivateRoute exact path="/dash/answer" component={AnswerContainer} />
          <PrivateRoute
            exact
            path="/dash/tickets"
            component={TicketContainer}
          />
          <PrivateRoute
            exact
            path="/dash/tickets/detail"
            component={TicketContainer}
          />
          <PrivateRoute
            exact
            path="/dash/tickets/create"
            component={TicketContainer}
          />
          <PrivateRoute
            exact
            path="/dash/tickets/edit"
            component={TicketContainer}
          />
          <PrivateRoute
            exact
            path="/dash/incident"
            component={TicketContainer}
          />
          {/* <PrivateRoute exact path="/dash/sla" component={SlaContainer} /> */}
          <PrivateRoute
            exact
            path="/dash/reports"
            component={ReportContainer}
          />
          <PrivateRoute
            exact
            path="/dash/reports/analystreportcall"
            component={AnalystReportCalls}
          />
          <PrivateRoute exact path="/dash/gcf" component={GCFContainer} />
          <PrivateRoute exact path="/dash/gi" component={GIContainer} />
          <PrivateRoute exact path="/dash/gsd" component={GSDContainer} />

          {/* <PrivateRoute
              exact
              path="/dash/subdepartment"
              component={DepartmentContainer}
            /> */}
          <PrivateRoute exact path="/dash/tag" component={TagContainer} />
          <PrivateRoute
            exact
            path="/dash/typecontracts"
            component={TypeContractContainer}
          />
          <PrivateRoute exact path="/dash/download" component={DownloadAgent} />
          {/* <PrivateRoute exact path="/dash/mail" component={MailContainer} /> */}
          {/* <PrivateRoute
              exact
              path="/dash/companytime"
              component={CompanyTimeContainer}
            /> */}
          {/* <PrivateRoute exact path="/dash/devicetype" component={DeviceType} /> */}
          <PrivateRoute exact path="/dash/site" component={Site} />
          <PrivateRoute exact path="/dash/panel" component={Panel} />
          <PrivateRoute exact path="/dash/bsc" component={Bsc} />
          <PrivateRoute
            exact
            path="/dash/newServiceOrder"
            component={ServiceOrderContainerForm}
          />
          <PrivateRoute
            exact
            path="/dash/auditcontract"
            component={AuditContainerNew}
          />
          {/* <PrivateRoute exact path="/dash/ad" component={ad} /> */}
          {/* <PrivateRoute exact path="/dash/Discovery" component={Discovery} /> */}
          <PrivateRoute exact path="/dash/deviceConf" component={DeviceConf} />
          {/* --------------------------------------Taskmanagement------------------------------------------ */}
          <PrivateRoute
            exact
            path="/dash/task"
            component={DashboardTaskManagement}
          />
          <PrivateRoute exact path="/dash/techtask" component={TechTask} />
          <PrivateRoute
            exact
            path="/dash/taskapprove"
            component={ApproveTask}
          />
          <PrivateRoute
            exact
            path="/dash/task/calendar"
            component={TaskCalendar}
          />
          <PrivateRoute
            exact
            path="/dash/task/ticket/:id"
            component={TicketTask}
          />
          <PrivateRoute exact path="/dash/portal" component={PortalContainer} />

          {/* ---------------SAC ROUTES----------------- */}

          {/* Cadastro de clientes */}
          <PrivateRoute
            exact
            path="/dash/sac/consumer-base"
            component={SacConsumerBase}
          />

          {/* Gestão de atendimentos*/}
          <PrivateRoute
            exact
            path="/dash/sac/dashboard"
            component={GSDContainerSac}
          />

          {/* LIsta de Tarefas*/}
          <PrivateRoute
            exact
            path="/dash/sac/listtasks"
            component={ListTasksAttendance}
          />

          {/* Cadastro de atendimento */}
          <PrivateRoute
            exact
            path="/dash/sac/service-registration"
            component={AttendanceContainer}
          />

          {/* -----Cadastro de temas---------- */}

          <PrivateRoute
            exact
            path="/dash/sac/SacThemeList"
            component={SacThemeList}
          />

          {/* -----Cadastro de topicos---------- */}

          <PrivateRoute
            exact
            path="/dash/sac/SacTopicList"
            component={TopicList}
          />

          {/* -----Cadastro de topicos---------- */}

          <PrivateRoute
            exact
            path="/dash/sac/SubjecList"
            component={SubjectContainer}
          />

          {/* -----Cadastro de Tarefas---------- */}
          {/* -------
          <PrivateRoute
            exact
            path='/dash/sac/SacTaskList'
            component={TaskContainer}
          />
          -------- */}
        </Switch>
      </Router>
    );
  }
}
